import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonMenu, ButtonMenuPlay } from '../components/Button'

import axios from 'axios'
import ErrorPage from './ErrorPage'
import Loading from '../components/Loading'
import moment from 'moment'

// const mockup = [
//   {
//       "round_id": 1,
//       "name": "รอบแรก 23 มิ.ย.",
//       "start_date": "2024-06-23 10:10:00",
//       "end_date": "2024-06-23 23:12:00",
//       "participate": 0
//   },
//   {
//       "round_id": 2,
//       "name": "รอบสอง 23 มิ.ย.",
//       "start_date": "2024-06-23 10:13:30",
//       "end_date": "2024-06-23 10:14:00",
//       "participate": 0
//   },
//   {
//       "round_id": 3,
//       "name": "รอบสาม 23 มิ.ย.",
//       "start_date": "2024-06-23 10:57:30",
//       "end_date": "2024-06-23 11:00:00",
//       "participate": 0
//   }
// ]


let loopIndex = 0
let dataRound = []
let dataRoundNotParticipate = []

function Lobby({dataCampaign}) {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [roundText, setRoundText] = useState('')

  const [btnWaiting, setBtnWaiting] = useState(false)
  const [btnReady, setBtnReady] = useState(false)

  // const registered = localStorage.getItem('eeRegistration')
  const authToken = localStorage.getItem('eeToken')

  const fetchTokenRegister = async () => {
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/ee/token_register`, {}, { headers: { 'Authorization': 'Bear '+ authToken }})
      // setDataTokenRegister(response.data)

      console.log('fetchTokenRegister',response.data)
      setIsLoading(false)

      // Set Token to localstorage
      localStorage.setItem('eeToken', response.data.token)

      if(response.data.need_prequiz) {
        navigate('/prequiz')
      }

      // Fetch Round Data
      fetchRound(response.data.token)

    } catch (error) {
      console.log('[Error]fetchTokenRegister', error)
      setIsLoading(false)
      setIsError(true)
    }

  }

  const fetchRound = async (authTokenNew) => {
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ee/round`, { headers: { 'Authorization': 'Bear '+ authTokenNew }})
      // setDataRound(response.data)
      dataRound = response.data

      console.log('fetchRound',response.data)
      setIsLoading(false)

    } catch (error) {
      console.log('[Error]fetchRound', error)
      setIsLoading(false)
      setIsError(true)
    }

  }

  useEffect(() => {

    // Date on Today 
    // - Check has eeEndDay on localstorage
    if(!!localStorage.getItem('eeEndDay')) {
      const nowDay = moment()
      const endDay = moment(Number(localStorage.getItem('eeEndDay')))
      let iscurrentDate = nowDay.isSame(endDay, "day")
  
      // console.log(nowDay,' && ',endDay,' = ',iscurrentDate)

      // - Check on Not Today
      if(!iscurrentDate) {
        navigate('/register')

      } else {
        // - on Today
        // Fetch Token Register again
        fetchTokenRegister()

      }

    } else {
      // - Check has not eeEndDay on localstorage
      navigate('/register')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      console.log('Check data round every milliseconds');
     
      buttonRound()
      // half 1 minute
    }, 1000);
  
    return () => { 
      clearInterval(interval); 
      
      loopIndex = 0
      dataRoundNotParticipate = []
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  function buttonRound() {
    const nowDate = new Date()
    const dataRoundArr = dataRound.round // mockup
    
    // Check data round length
    if(loopIndex < dataRoundArr.length) {
      // Different now date with start_date, end_date from data round
      // let checkStartDate = (moment(dataRoundArr[loopIndex]?.start_date).diff(moment(nowDate), 'milliseconds'))
      let checkEndDate = (moment(dataRoundArr[loopIndex]?.end_date).diff(moment(nowDate), 'milliseconds'))

      // Check value participate && Check end date && Check dataRoundNotParticipate length (has value?)
      // console.log('checkStartDate:',checkStartDate,', checkEndDate:',checkEndDate)
      if( (!dataRoundArr[loopIndex].participate) && (checkEndDate > 0) && (dataRoundNotParticipate.length === 0) ) {
        // Correct data round to array of dataRoundNotParticipate
        dataRoundNotParticipate.push(dataRoundArr[loopIndex])
      }

      // Increase Loop Index
      loopIndex++
    }

    // Check dataRoundNotParticipate length
    // If have value
    if(dataRoundNotParticipate.length) {
      // Different now date with start_date, end_date from dataRoundNotParticipate
      let checkRoundStartDate = (moment(dataRoundNotParticipate[0]?.start_date).diff(moment(nowDate), 'milliseconds'))
      let checkRoundEndDate = (moment(dataRoundNotParticipate[0]?.end_date).diff(moment(nowDate), 'milliseconds'))

      // console.log('START:',checkRoundStartDate,', END:',checkRoundEndDate)
      // Set text round on button
      setRoundText(`${dataRoundNotParticipate[0]?.name}`)

      // Check button status
      if(checkRoundStartDate > 0 && checkRoundEndDate > 0) {
        console.log('---- Round Status: waiting ----')
        setBtnReady(false)
        setBtnWaiting(true)
      } else if(checkRoundStartDate < 0 && checkRoundEndDate > 0) {
        console.log('---- Round Status: ready ----')
        setBtnReady(true)
        setBtnWaiting(false)
      } else {
        // Reset loop index, dataRoundNotParticipate
        loopIndex = 0
        dataRoundNotParticipate = []

        console.log('---- Round Status: not ready ----')
        setBtnReady(false)
        setBtnWaiting(false)
      }
    }

    // console.log(dataRoundNotParticipate)
  }

  return (
    <>
    {/* Check Loading */}
      {
        isLoading ? (
          <div className="absolute w-full h-full">
            <Loading />
          </div>
        ) : null
      }

      {/* Check Error */}
      {
        (isError && !isLoading) ? (
          <ErrorPage text={'ไม่สามารถทำรายการได้'} addclass={'mt-32'} />
        ) : null
      }

      {
        (!isError && !isLoading) ? (
          <div className="px-5 pt-7 pb-3">
            {/* Topic */}
            <h1 className="text-center text-xl font-medium mb-5">ร่วมสนุกรับทรัพย์<br/>ทุกสัปดาห์</h1>

            {/* Banner */}
            {
              !!dataCampaign?.lobby_banner ?
              <img className="w-full rounded-3xl" src={dataCampaign?.lobby_banner} alt="banner" onClick={()=> navigate('/herobanner')} /> 
              : null
            }

            {/* Menu */}
            <ul>
              <li className="mt-5 mb-2 px-4">
                <ButtonMenu text="อ่านบทความสำหรับเล่นเกม" addclass={'bg-white w-full text-blacktextquiz'} action={()=> navigate('/article')} />
              </li>
              <li className="mt-5 mb-2 px-4">
                {/* {
                  (roundStart && !roundEnd) ?
                    // Participate is already (Start = true, End = false)
                      <ButtonMenuPlay text="กดเล่นกิจกรรม" subtext={roundText} addclass={`bg-yellowbtn w-full text-white`} />
                  : (roundStart && roundEnd) || (!roundStart && roundEnd) ?
                    // Participate is not ready (Start = true, End = ture)(Start = false, End = ture) 
                      <ButtonMenuPlay text="กดเล่นกิจกรรม" subtext={''} addclass={`bg-graybtn w-full text-white`} />
                  :
                    // Participate is not ready (Start = false, End = false)
                      <ButtonMenuPlay text="กดเล่นกิจกรรม" subtext={roundText} addclass={`bg-graybtn w-full text-white`} />
                } */}
                {
                  btnReady ?
                    <ButtonMenuPlay text="กดเล่นกิจกรรม" subtext={roundText} addclass={`bg-yellowbtn w-full text-bluetextquiz`} 
                      action={()=> 
                        navigate('/quiz', { 
                          state: { 
                            name: dataRoundNotParticipate[0].name, 
                            start_date: `${moment(dataRoundNotParticipate[0].start_date).format('HH:mm')} น.`, 
                            end_date: `${moment(dataRoundNotParticipate[0].end_date).format('HH:mm')} น.`
                          } 
                        }
                      )} 
                    /> 
                  :
                  btnWaiting ?
                    <ButtonMenuPlay text="กดเล่นกิจกรรม" subtext={roundText} addclass={`bg-graybtn w-full text-white`} />
                  :
                    <ButtonMenuPlay text="กดเล่นกิจกรรม" subtext={''} addclass={`bg-graybtn w-full text-white`} />
                }
              </li>
              <li className="mt-5 mb-2 px-4">
                <ButtonMenu text="กติกาและเงื่อนไข" addclass={'bg-white w-full text-blacktextquiz'} action={()=> navigate('/rule')}  />
              </li>
              <li className="mt-5 mb-2 px-4">
                <ButtonMenu text="ประกาศรายชื่อผู้ที่ได้รับรางวัล" addclass={'bg-white w-full text-blacktextquiz'} action={()=> navigate('/leaderboard')}  />
              </li>
            </ul>
          </div>
        ) : null
      }
    </>
  )
}

export default Lobby