import React from 'react'
import { FaAngleLeft } from 'react-icons/fa'

export default function Header({text, addclass, action}) {
    return (
        <div className="flex justify-between items-center">
            <FaAngleLeft color="#000F85" size={'1.4rem'} onClick={action} />
            <h1 className={`text-center text-xl font-normal ${addclass}`}>{text}</h1>
            <div className="w-5">&nbsp;</div>
        </div>
    )
}
