import React from 'react'

function Footer() {
  return (
    <div className="footer fixed bottom-0 w-full p-3 bg-graybg font-kanit">
        <div className="m-auto max-w-2xl">
            <p className="text-white font-[400] text-sm text-center">สำหรับสื่อสารกับพนักงานบริษัท ซีพี ออลล์ จำกัด (มหาชน) ที่มีอายุตั้งแต่ 18 ปีขึ้นไป ผลิตภัณฑ์ยาสูบเป็นสารเสพติดที่ทำลายสุขภาพ</p>
        </div>
    </div>
  )
}

export default Footer