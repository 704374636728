import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import { ButtonNext } from '../components/Button'

function Rule({dataCampaign}) {
    const navigate = useNavigate()

    useEffect(() => {
        // Check registered
        const hasDataUser = !!localStorage.getItem("dataUser") 
        if(!hasDataUser) {
            navigate('/')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="rule my-7">
            {/* Header */}
            <div className="px-5 mb-5">
                <Header text="กติกาและเงื่อนไข" action={()=> navigate('/') } />
            </div>

            {/* Rule */}
            <div className="table-rule px-5" dangerouslySetInnerHTML={{__html: dataCampaign.term_condition}}></div>

            <div className="pb-5">
                <ButtonNext text="กลับหน้าหลัก" addclass="m-auto" action={()=> navigate('/') } />
            </div>
        </div>
    )
}

export default Rule