import React from 'react'
import { FaExclamationCircle } from "react-icons/fa"

function ErrorPage({text, addclass}) {
  return (
    <div className={`m-auto text-center relative h-full ${addclass}`}>
      <FaExclamationCircle color='#ffffff' size={'3rem'} className="m-auto mb-5" />
      <p className="text-white w-full flex items-center justify-center">
        ขออภัย {text}<br/>
        กรุณากลับเข้ามาใหม่ในภายหลัง
      </p>
    </div>
  )
}

export default ErrorPage