import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { ButtonConsent, ButtonSubmit } from '../components/Button'
import { useForm } from 'react-hook-form';
import { FaTimes } from "react-icons/fa";

import Stepper from '../components/Stepper';
import axios from 'axios';
import Modal from 'react-modal';
import moment from 'moment';

import { AllDataContext } from '../App';
import ErrorPage from './ErrorPage';
import Loading from '../components/Loading';

function Register({dataCampaign}) {
  const dataFromContext = useContext(AllDataContext)

  const navigate = useNavigate()

  const { register, handleSubmit, reset, /*watch,*/ formState: { errors } } = useForm({
    defaultValues: {
      branch_code: "",
      employee_number: ""
    }
  });

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openConsent, setOpenConsent] = useState(false)

  // console.log('employee_number', !!watch("employee_number")?true:false,' , ','branch_code', !!watch("branch_code")?true:false,' , ','accept_all', !!watch("accept_all")?true:false); 
  // console.log(errors)
  
  useEffect(() => {
    setIsLoading(false)
    // Prefill Data User
    if (localStorage.getItem("dataUser") !== null) {
      reset({
        branch_code: JSON.parse(localStorage.getItem("dataUser")).branch_code || '',
        employee_number: JSON.parse(localStorage.getItem("dataUser")).employee_number || ''
      })
    }

    // Check Accept Consent
    if (localStorage.getItem("eeConsent") === null) {
      setOpenConsent(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function fetchAcceptConsent() {
    // Fetch Post Data User
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/consent/log`, 
        {
          "app": "ee"
        },
        { headers: { 'x-application-secret-key': dataFromContext.dataAuth.apiXKey } }
      )

      setIsLoading(false)
      console.log('fetchAcceptConsent',response.data)

      // Set Consent to localstorage
      localStorage.setItem('eeConsent', true)

      setOpenConsent(false)

    } catch (error) {
      setIsError(true)
      setIsLoading(false)
    }
  }
  

  function onError() {
    setOpenModal(true)
  }

  async function onSubmit(data) {
    document.activeElement.blur()
    setOpenModal(false)

    const dataUser = {
      "branch_code": data.branch_code,
      "employee_number": data.employee_number,
      "accept_all": 1
    }

    // Fetch Post Data User
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/ee/register`, 
        dataUser,
        { headers: { 'x-application-secret-key': dataFromContext.dataAuth.apiXKey } }
      )

      setIsLoading(false)
      console.log('fetchRegister',response.data)

      // Set Token to localstorage
      localStorage.setItem('eeToken', response.data.token)

      // Set dataUser to localstorage
      localStorage.setItem('dataUser', JSON.stringify(dataUser))

      // Set Token to localstorage
      localStorage.setItem('eeEndDay', moment().endOf('day').valueOf())

      // goto Pre Quiz
      if(response.data.need_prequiz) {
        navigate('/prequiz');
      } else {
        // Registration
        localStorage.setItem('eeRegistration',response.data.need_prequiz)

        navigate('/')
      }
    } catch (error) {
      setIsError(true)
      setIsLoading(false)
    }

  }

  Modal.setAppElement('#root');

  return (
    <>
      {/* Check Loading */}
      {
        isLoading ? (
          <div className="absolute w-full h-full">
            <Loading />
          </div>
        ) : null
      }

      {/* Check Error */}
      {
        (isError && !isLoading) ? (
          <ErrorPage text={'ไม่สามารถทำรายการได้'} addclass={'mt-32'} />
        ) : null
      }

      {
        (!isError && !isLoading) ? (
          <div className="px-5 py-7">
            {/* Topic */}
            <h1 className="text-center text-xl font-medium">ร่วมเล่นรับทรัพย์ทุกสัปดาห์</h1>

            {/* Stepper */}
            <Stepper page={1} />

            {/* Banner */}
            {
              !!dataCampaign?.register_banner ?
              <img className="w-full rounded-3xl" src={dataCampaign?.register_banner} alt="banner" /> 
              : null
            }

            {/* Form Register */}
            <form className="form-register pt-8" onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="row pb-4 flex flex-col">
                <label className="pb-1">รหัสพนักงาน<span>*</span></label>
                <input type="number" onInvalid={(e) => e.target.setCustomValidity("กรุณากรอกเป็นตัวเลข")} onInput={(e) => e.target.setCustomValidity('')} className="text-blacktextinput rounded p-2 placeholder:text-graytextplaceholder" placeholder="รหัสพนักงาน" {...register("employee_number", { required: true, })} />
                {/* <input type="text" className="text-blacktextinput" value={dataRegister.employee_number} onChange={handleCodeEmployee} /> */}
              </div>
              <div className="row pb-4 flex flex-col">
                <label className="pb-1">รหัสสาขา<span>*</span></label>
                <input type="number" onInvalid={(e) => e.target.setCustomValidity("กรุณากรอกเป็นตัวเลข")} onInput={(e) => e.target.setCustomValidity('')} className="text-blacktextinput rounded p-2 placeholder:text-graytextplaceholder" placeholder="รหัสสาขา" {...register("branch_code", { required: true, minLength: 5, maxLength: 5, })} />
                {/* <input type="text" className="text-blacktextinput" value={dataRegister.branch_code} onChange={handleCodeBranch} /> */}
              </div>
              <div className="row pt-2 pb-5 flex items-baseline">
                {/* <input type="checkbox" checked={dataRegister.accept_all} onChange={handleAcceptAll} /> */}
                <label>
                  <input type="checkbox" className="mr-2" {...register("accept_all", { required: true }) } /> ยืนยันว่าเป็นผู้มีอายุตั้งแต่ 18 ปีขึ้นไป และ ยืนยัน ว่าฉันเป็นผู้จัดจำหน่ายยาสูบ รวมถึงยอมรับ ข้อกำหนดในการเข้าร่วมรายการ
                </label>
              </div>

              {
                !openConsent ? <ButtonSubmit addclass={`m-auto`} text={'เข้าร่วมกิจกรรม'} /> : null
              }
            </form>

            {/* Modal Noti */}
            <Modal 
              isOpen={ openModal && (Object.keys(errors).length !== 0) } // Check state openModal and has error
              className="Modal font-kanit"
              overlayClassName="Overlay"
            >
              <div className="Modal-header bg-graymodalheader flex justify-between items-center px-5 py-2">
                <p>คำเตือน</p>
                <div className="rounded-full relative h-full bg-brownbtnclose p-1">
                  <FaTimes color="#ffffff" size={'.8rem'} onClick={()=> setOpenModal(false) } />
                </div>
              </div>
              <div className="Modal-body text-blacktextquiz px-5 py-3">
                {errors.employee_number?.type === 'required' && <p>กรุณาใส่ข้อมูล รหัสพนักงาน</p>}
                {errors.branch_code?.type === 'required' && <p>กรุณาใส่ข้อมูล รหัสสาขา</p>}
                {errors.branch_code?.type === 'minLength' && <p>กรุณาใส่ข้อมูล รหัสสาขา ให้ครบ 5 หลัก</p>}
                {errors.branch_code?.type === 'maxLength' && <p>กรุณาใส่ข้อมูลร หัสสาขา ไม่เกิน 5 หลัก</p>}
                {errors.accept_all?.type === 'required' && <p>กรุณากดยืนยัน</p>}
              </div>
            </Modal>

            {/* Modal Consent */}
            <Modal 
              isOpen={ openConsent } // Check consent
              className="Modal consent-modal font-kanit"
              overlayClassName="Overlay consent-modal"
            >
              <div className="Modal-header bg-greenbgcookie items-center p-5">
                <p className="!text-greentextcookie mb-5 text-sm">เราใช้คุกกี้ (และเทคโนโลยีอื่นที่คล้ายกัน) เพื่อ:<br/> การทำงานของเว็บไซต์และเพื่อจดจำการตั้งค่าของคุณ ปรับปรุงเว็บไซต์โดยการรวบรวมข้อมูลเกี่ยวกับการใช้งานของคุณ ติดตามอุปกรณ์ของคุณบนเว็บไซต์นี้และไซต์อื่น ๆ เพื่อให้เราสามารถนำเสนอประสบการณ์ที่ปรับให้ตรงกับบุคคลมากขึ้น และปรับเนื้อหาการสื่อสารของเราเพื่อให้เหมาะกับความต้องการของคุณ โปรดศึกษาเพิ่มเติมที่ <a target="_blank" href="https://www.pmiprivacy.com/thailand/th/traders" rel="noopener noreferrer">ประกาศเกี่ยวกับความเป็นส่วนตัว</a></p>
                <div>
                  <ButtonConsent text="ตกลง" addclass={'m-auto !text-white !justify-center w-1/2 !rounded-md'} action={()=> fetchAcceptConsent() } />
                </div>
              </div>
            </Modal>
          </div>
        ) : null
      }
    </>
  )
}

export default Register