import React from 'react'
import { FaChevronRight } from "react-icons/fa";

export function ButtonSubmit({addclass, text, action}) {
  return (
    <button type="submit" className={`bg-yellowbtn rounded-full px-7 py-2 flex justify-between items-center shadow-md font-normal text-md ${addclass}`} onClick={action}> {text}&nbsp;&nbsp;<FaChevronRight /></button>
  )
}

export function ButtonNext({addclass, text, action}) {
  return (
    <button type="button" className={`bg-yellowbtn rounded-full px-7 py-2 flex justify-between items-center shadow-md font-normal text-md ${addclass}`} onClick={action}> {text}&nbsp;&nbsp;<FaChevronRight /></button>
  )
}

export function ButtonConfirm({addclass, text, action}) {
  return (
    <button type="button" className={`bg-yellowbtn rounded-full px-7 py-2 flex justify-between items-center shadow-md ${addclass}`} onClick={action}> {text}</button>
  )
}

export function ButtonMenu({addclass, text, action, icon}) {
    return (
      <button type="button" className={`rounded-full px-7 py-3 items-center shadow-md text-center font-normal ${(icon)?'flex justify-center text-base':''} ${addclass}`} onClick={action}> {text} {(icon)?(<FaChevronRight className="ml-3" />):null}</button>
    )
}

export function ButtonMenuPlay({addclass, text, subtext, action}) {
  return (
    <button type="button" className={`rounded-full px-2 ${!!(subtext)?'py-1':'py-3'} items-center shadow-md text-center font-normal flex justify-center text-base ${addclass}`} onClick={action}> <p className="flex flex-col"><span>{text}</span><span className="text-sm font-light">{subtext}</span></p>&nbsp;&nbsp;<FaChevronRight size={'1.6rem'} /></button>
  )
}

export function ButtonQuiz({elRef, elStyle, id, idselected, orderNo, addclass, text, action}) {
  return (
    <button type="button" style={elStyle} ref={elRef} id={id} className={`btn-quiz rounded-full px-6 py-4 items-center shadow-md text-center font-normal text-base leading-none-tight ${(Number(id) === Number(idselected)) ? 'bg-yellowbtn text-bluetextquiz':'bg-white text-blacktextquiz'} w-full ${addclass}`} onClick={action}>{text}</button>
  )
}

export function ButtonConsent({addclass, text, action}) {
  return (
    <button type="button" className={`bg-greenbtncookie rounded-full px-7 py-2 flex justify-between items-center shadow-md ${addclass}`} onClick={action}> {text}</button>
  )
}