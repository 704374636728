
import React, { useEffect ,useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaTimes, FaExclamationCircle, /*FaAngleLeft*/ } from "react-icons/fa";
import { ButtonConfirm, ButtonNext } from '../components/Button';

import Modal from 'react-modal';
import axios from 'axios'
import Loading from '../components/Loading';
import Stepper from '../components/Stepper';
import Header from '../components/Header';


// Init All Choise
// let quizNo = stepperNo - 2
let correctTotal = 0
let choiceSelectedArr = []

function PreQuiz() {
  const navigate = useNavigate()

  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [dataPreQuiz, setDataPreQuiz] = useState([])
  const [stepperNo, setStepperNo] = useState(2)
  const [quizNo, setQuizNo] = useState(0)
  const [answerTotal, setAnswerTotal] = useState(0)
  const [errorText, setErrorText] = useState('เกิดข้อผิดพลาก กรุณาลองใหม่อีกครั้ง')

  const authToken = localStorage.getItem('eeToken')

  const fetchPreQuiz = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ee/prequiz`, { headers: { 'Authorization': 'Bear '+ authToken }})
      setDataPreQuiz(response.data.prequiz)
      console.log('fetchPreQuiz',response.data.prequiz)
      setIsLoading(false)
    } catch (error) {
      console.log('[Error]fetchPreQuiz', error)
      setIsLoading(false)
      setIsError(true)
    }
  }

  const fetchFinishPreQuiz = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/ee/finish_prequiz`, {}, { headers: { 'Authorization': 'Bear '+ authToken }})
      console.log('fetchFinishPreQuiz',response.data)

      // Registration = 0 (don't make prequiz) after finish prequiz
      localStorage.setItem('eeRegistration',0)

      // Goto Lobby
      navigate('/')

      setIsLoading(false)
    } catch (error) {
      console.log('[Error]fetchFinishPreQuiz', error)
      setIsLoading(false)
      setIsError(true)
      // Registration
      localStorage.setItem('eeRegistration',0)
    }
  }

  // UseEffect - Fetch Data
  useEffect(() => {
    // Check registered
    const hasDataUser = !!localStorage.getItem("dataUser") 
    
    if(!hasDataUser) {
        navigate('/')
    }

    fetchPreQuiz()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // UseEffect - Get Correct Amount
  useEffect(() => {
    // Reset 
    correctTotal = 0
    choiceSelectedArr = []
    setAnswerTotal(0)
    document.querySelectorAll('input[type=checkbox]').forEach( el => el.checked = false )

    for(let i=0; i<dataPreQuiz[quizNo]?.choice_list.length; i++ ) {
      correctTotal = Number(correctTotal + dataPreQuiz[quizNo]?.choice_list[i].correct)
    }

    // console.log('start:',dataPreQuiz[quizNo],', ',correctTotal)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPreQuiz, stepperNo])

  // Handle Checkbox
  function handleChoiceSelect(e) {
    // Close Modal
    setOpenModal(false)

    // Add ID select to array and Increase Answer
    if(e.target.checked) {
      choiceSelectedArr.push(e.target.id)

      setAnswerTotal(answerTotal + Number(e.target.value))
    } else {
      // Remove ID select from array
      const index = choiceSelectedArr.indexOf(e.target.id);
      if (index > -1) { // only splice array when item is found
        choiceSelectedArr.splice(index, 1); // 2nd parameter means remove one item only
      }

      setAnswerTotal(answerTotal - Number(e.target.value))
    }

    // console.log(choiceSelectedArr,', length: ',choiceSelectedArr.length)
  }

  // Check Answer
  function checkAnswer() {
    setOpenModal(false)

    // Check Answer Amount
    if(choiceSelectedArr.length !== correctTotal) {
      setOpenModal(true)
      setErrorText(`กรุณาเลือก ${correctTotal} คำตอบ`)
    } else {
      // Check Answer Correct
      if(answerTotal === correctTotal) {

        // Check Stepper
        if(stepperNo === 2) {
          setStepperNo(3)
          setQuizNo(1)
        } else {
          fetchFinishPreQuiz()
        }

      } else {
        setOpenModal(true)
        setErrorText('คุณตอบไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง')
      }
    }
  }
  
  Modal.setAppElement('#root');

  return (
    <>
      {/* Check Loading */}
      {
        isLoading ? (
          <div className="absolute w-full h-full">
            <Loading />
          </div>
        ) : null
      }

      {/* Pre Quiz */}
      {
        (!isError && !isLoading) ? (
          <>
            <div className="pt-7 px-5 pb-0">
              {/* header */}
              {/* <div className="flex justify-between items-center">
                <FaAngleLeft color="#ffffff" size={'1.4rem'} onClick={()=> navigate("/register")} />
                <h1 className="text-center text-xl font-normal">อุ่นเครื่องก่อนเล่นกิจกรรม</h1>
                <div className="w-5">&nbsp;</div>
              </div> */}
              <Header text="อุ่นเครื่องก่อนเล่นกิจกรรม" action={()=> navigate("/register")} />

              {/* Stepper */}
              <Stepper page={stepperNo} />
            </div>

            {/* Quiz Layout */}
            <div>
              {/* Banner */}
              {
                !!dataPreQuiz[quizNo]?.banner ?
                  <div className="px-5 pb-5"><img className="w-full rounded-3xl" src={dataPreQuiz[quizNo]?.banner} alt="banner" /> </div>
                : null
              }

              {/* Question and Choice */}
              <div className={`${(quizNo)?'text-bluetextquiz' : 'text-bluetextquiz'} bg-white p-5`}>
                {/* Question */}
                <h1 className="text-base font-normal mb-3">{ dataPreQuiz[quizNo]?.question }</h1>
                  

                {/* Choice List */}
                <ul>
                {
                  dataPreQuiz[quizNo]?.choice_list.map((item,i)=> {
                    return (
                      <li key={i} className="py-1">
                        <label>
                          <input type="checkbox" id={i+1} className="mr-2" name={item.choice} value={item.correct} onChange={(e)=> handleChoiceSelect(e) } />
                          {item.choice}
                        </label>
                      </li>
                    )
                  })
                }
                </ul>
              </div>
              <div className="py-5">
                <ButtonNext text="ส่งคำตอบ" addclass="m-auto" action={()=> checkAnswer() } />
              </div>
            </div>
          </>
        ) : null
      }

      {/* Modal Error */}
      <Modal 
          isOpen={ isError }
          className="Modal font-kanit"
          overlayClassName="Overlay"
        >
          <div className="Modal-header bg-graymodalheader flex justify-between items-center px-5 py-2">
            <p>คำเตือน</p>
            <div className="rounded-full relative h-full bg-brownbtnclose p-1">
              <FaTimes color="#ffffff" size={'.8rem'} onClick={()=> setOpenModal(false) } />
            </div>
          </div>
          <div className="Modal-body text-blacktextquiz px-5 py-3">
            <FaExclamationCircle color='#B10A11' size={'3rem'} className="m-auto mb-5" />
            <p className="text-center mb-3">ไม่สามารถทำรายการได้</p>
            <ButtonConfirm text="กลับสู่หน้าหลัก" addclass="m-auto" action={()=> navigate("/")} />
          </div>
        </Modal>

        {/* Modal Error */}
        <Modal 
          isOpen={ openModal }
          className="Modal font-kanit"
          overlayClassName="Overlay"
        >
          <div className="Modal-header bg-graymodalheader flex justify-between items-center px-5 py-2">
            <p>คำเตือน</p>
            <div className="rounded-full relative h-full bg-brownbtnclose p-1">
              <FaTimes color="#ffffff" size={'.8rem'} onClick={()=> setOpenModal(false) } />
            </div>
          </div>
          <div className="Modal-body text-blacktextquiz px-5 py-3">
            <p className="text-center mb-3">{errorText}</p>
          </div>
        </Modal>
    </>
  )
}

export default PreQuiz