import { useEffect, useState, createContext } from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Lobby from './pages/Lobby';
import PreQuiz from './pages/PreQuiz';
import Quiz from './pages/Quiz';
import Register from './pages/Register';
import Result from './pages/Result';
import axios from 'axios';
import Loading from './components/Loading';
import ErrorPage from './pages/ErrorPage';
import HeroBanner from './pages/HeroBanner';
import Rule from './pages/Rule';
import Article from './pages/Article';
import Leaderboard from './pages/Leaderboard';

const AllDataContext = createContext()

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const [dataCampaign, setDataCampaign] = useState([])
  const [isError, setIsError] = useState(false)

  const [dataAuth] = useState({
    apiXKey: 'b5e64ba6-1d27-4c40-bc65-2e686e394d62'
  })

  const fetchCheckCampaign = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ee/check_campaign`, { headers: { 'x-application-secret-key': dataAuth.apiXKey }})
      setDataCampaign(response.data)
      console.log('fetchCheckCampaign',response.data)
      setIsLoading(false)
    } catch (error) {
      console.log('[Error]fetchCheckCampaign', error)
      setIsLoading(false)
      setIsError(true)
    }
  }

  useEffect(() => {
    fetchCheckCampaign()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const router = createBrowserRouter([
    {
      path: "/",
      element: <Lobby dataCampaign={dataCampaign} />,
    },
    {
      path: "/register",
      element: <Register dataCampaign={dataCampaign} />
    },
    {
      path: "/prequiz",
      element: <PreQuiz />
    },
    {
      path: "/quiz",
      element: <Quiz dataCampaign={dataCampaign} />
    },
    {
      path: "/result",
      element: <Result />
    },
    {
      path: "/herobanner",
      element: <HeroBanner dataCampaign={dataCampaign} />
    },
    {
      path: "/article",
      element: <Article dataCampaign={dataCampaign} />
    },
    {
      path: "/rule",
      element: <Rule dataCampaign={dataCampaign} />
    },
    {
      path: "/leaderboard",
      element: <Leaderboard dataCampaign={dataCampaign} />
    },
    {
      path: "*",
      element: <Lobby dataCampaign={dataCampaign} />
    }
  ]);


  return (
    <AllDataContext.Provider value={{dataAuth}}>
      <div className="max-w-2xl h-screen m-auto relative font-kanit overflow-auto">
        {/* Check Loading */}
        {
          isLoading ? (
            <div className="absolute w-full h-full">
              <Loading />
            </div>
          ) : null
        }

        {/* Check Error */}
        {
        (isError && !isLoading) ? (
          <ErrorPage text={'เกิดข้อผิดพลาด'} addclass={'mt-32'} />
          ) : null
        }

        {/* Check Campaign Active = 1 */}
        {
          (dataCampaign.active && !isLoading) ? (
            <div className="mb-16 xs:mb-20">
              <RouterProvider router={router} />
            </div>
          ) : null
        }

        {/* Check Campaign Active = 0 */}
        {
          (!dataCampaign.active && !isLoading && !isError) ? (
            <div className="m-auto text-center relative h-full">
              <p className="text-white h-full w-full flex items-center justify-center">
                ขออภัย แคมเปญยังไม่เริ่ม<br/>
                กรุณากลับเข้ามาใหม่ในภายหลัง
              </p>
            </div>
          ) : null
        }
      </div>
    </AllDataContext.Provider>
  );
}

export { AllDataContext }
export default App;
