import React from 'react'
import {FaCircle, FaCheck } from "react-icons/fa";

function Stepper({page}) {
    const iconSize = '.6rem'

    function iconChecked() {
        return (
            <div className="rounded-full p-2 bg-yellowbtn">
                <FaCheck size={iconSize} />
            </div>
        )
    }

    function iconUnChecked() {
        return (
            <div className="rounded-full p-2 border border-yellowbtn">
                <FaCircle size={iconSize} color={'#F9F60D'} />
            </div>
        )
    }

    return (
        <div className="stepper flex justify-evenly items-center w-6/12 m-auto py-5">
            { iconChecked() }
            <div className={`link w-full border-t ${(page >= 1) ? 'border-yellowbtn':'border-graystopperline'}`}></div>
            { (page >= 2)? iconChecked() : iconUnChecked() }
            <div className={`link w-full border-t ${(page >= 2) ? 'border-yellowbtn':'border-graystopperline'}`}></div>
            { (page === 3)? iconChecked() : iconUnChecked()  }
        </div>
    )
}

export default Stepper