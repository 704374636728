import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header'
import { ButtonNext } from '../components/Button'

function Article({dataCampaign}) {
    const navigate = useNavigate()

    useEffect(() => {
        // Check registered
        const hasDataUser = !!localStorage.getItem("dataUser") 
        if(!hasDataUser) {
            navigate('/')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <div className="my-7">
            {/* Header */}
            <div className="px-5 mb-5">
                <Header text="อ่านบทความสำหรับเล่นเกม" action={()=> navigate('/') } />
            </div>

            {/* Article */}
            {
              !!dataCampaign?.article ?
              <img className="w-full m-auto" src={dataCampaign?.article} alt="banner" /> 
              : null
            }

            <div className="py-5">
                <ButtonNext text="กลับหน้าหลัก" addclass="m-auto" action={()=> navigate('/') } />
            </div>
        </div>
    )
}

export default Article