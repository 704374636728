import React from 'react'

function Loading() {
  return (
    <div className="m-auto text-center relative h-full">
        <p className="text-white h-full w-full flex items-center justify-center">
        ...Loading...
        </p>
    </div>
  )
}

export default Loading