import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonNext } from '../components/Button'
import axios from 'axios'

import Header from '../components/Header'
import Loading from '../components/Loading'
import ErrorPage from './ErrorPage'

// const mockup = [
//         {
//             "round_id": 21,
//             "name": "รอบแรก 1 ก.ค. 2567 10:00-12:00",
//             "has_leaderboard": 1
//         },
//         {
//             "round_id": 73,
//             "name": "รอบสอง 2 ก.ค. 2567 12:00 - 15:00 ",
//             "has_leaderboard": 0
//         },
//         {
//             "round_id": 75,
//             "name": "รอบสาม",
//             "has_leaderboard": 0
//         },
//         {
//             "round_id": 76,
//             "name": "รอบสี่",
//             "has_leaderboard": 0
//         },
//         {
//             "round_id": 83,
//             "name": "รอบห้า",
//             "has_leaderboard": 0
//         }
//     ]

function Leaderboard({dataCampaign}) {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingTable, setIsLoadingTable] = useState(false)
    const [isError, setIsError] = useState(false)

    const [dataLeaderboardFilter, setDataLeaderboardFilter] = useState([])
    const [dataLeaderboardTable, setDataLeaderboardTable] = useState([])
    const [dataLeaderboardUser, setDataLeaderboardUser] = useState({})
    const [currentRoundId, setCurrentRoundId] = useState(0)

    const authToken = localStorage.getItem('eeToken')

    const fetchLeaderboardTable = async (roundID) => {
    
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/ee/leaderboard/${roundID}`, { headers: { 'Authorization': 'Bear '+ authToken }})
          // setDataRound(response.data)
          setDataLeaderboardTable(response.data.top)
          setDataLeaderboardUser(response.data.user)
    
          console.log('fetchLeaderboardTable',response.data.top) 
          console.log('fetchLeaderboardTableUser',response.data.user)
          setIsLoading(false)
          setIsLoadingTable(false)
    
        } catch (error) {
          console.log('[Error]fetchLeaderboardTable', error)
          setIsLoading(false)
          setIsLoadingTable(false)
          setIsError(true)
        }
    
    }

    const fetchLeaderboardFilter = async () => {

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/ee/leaderboard`, { headers: { 'Authorization': 'Bear '+ authToken }})
            // setDataRound(response.data)
            let resDataRound =  response.data.round // mockup

            setDataLeaderboardFilter(resDataRound)

            console.log('fetchLeaderboardFilter',resDataRound)
            setIsLoading(false)
            setIsLoadingTable(false)

            let currentRoundIndex = 0
            for(let i=0; i<resDataRound.length; i++) {
                if(resDataRound[i].has_leaderboard === 1) {
                    currentRoundIndex = i
                }
            }
            
            setCurrentRoundId(resDataRound[currentRoundIndex].round_id)
            fetchLeaderboardTable(resDataRound[currentRoundIndex].round_id)



        } catch (error) {
            console.log('[Error]fetchLeaderboardFilter', error)
            setIsLoading(false)
            setIsLoadingTable(false)
            setIsError(true)
        }

    }
    useEffect(() => {
        // Check registered
        const hasDataUser = !!localStorage.getItem("dataUser") 
        if(!hasDataUser) {
            navigate('/')
        }

        fetchLeaderboardFilter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    function handleFilterRound(e) {
        setIsLoadingTable(true)
        
        let selectRoundID = e.target.value
        setCurrentRoundId(selectRoundID)
        fetchLeaderboardTable(selectRoundID)
    }

    return (
        <>
            {
                isLoading ? (
                <div className="absolute w-full h-full">
                    <Loading />
                </div>
                ) : null
            }

            {/* Check Error */}
            {
                (isError && !isLoading) ? (
                <ErrorPage text={'ไม่สามารถทำรายการได้'} addclass={'mt-32'} />
                ) : null
            }

            {
                (!isError && !isLoading) ? (
                <div className="my-7">
                    {/* Header */}
                    <div className="px-5 mb-5">
                        <Header text="ประกาศรายชื่อผู้ที่ได้รับรางวัล" action={()=> navigate('/') } />
                    </div>

                    {/* Banner */}
                    {
                    !!dataCampaign?.quiz_banner ?
                    <div className="px-5 mb-5">
                        <img className="w-auto m-auto" src={dataCampaign?.quiz_banner} alt="banner" /> 
                    </div>
                    : null
                    }

                    {/* Select Filter */}
                    <div className="px-5 mb-5">
                        <select className="text-blacktextinput w-full px-2 py-1 rounded-md border-graybdinput border font-normal" defaultValue={currentRoundId} onChange={handleFilterRound}>
                            {/* <option>Please choose one option</option> */}
                            {
                                dataLeaderboardFilter?.filter((option)=> option.has_leaderboard === 1).map((option, index) => {
                                    return (
                                        <option key={index} value={option.round_id} disabled={!option.has_leaderboard}>
                                            {option.name}
                                        </option>
                                    );
                                })
                            }
                        </select>
                    </div>

                    

                    {/* Table */}
                    <div className="table-leaderboard px-5 mb-5">
                        <table>
                            <thead>
                                <tr>
                                    <th>ลำดับ</th>
                                    <th>รหัสพนักงาน</th>
                                    <th>รหัสสาขา</th>
                                    <th>รางวัล</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !isLoadingTable && dataLeaderboardUser ?
                                        <tr className="row-user">
                                            <td>{dataLeaderboardUser.number}</td>
                                            <td>{dataLeaderboardUser.employee_number}</td>
                                            <td>{dataLeaderboardUser.branch_code}</td>
                                            <td>{dataLeaderboardUser.reward_name}</td>
                                        </tr>
                                    : null
                                    
                                }
                                {
                                    !isLoadingTable && dataLeaderboardTable.map((item,i)=> {
                                        return(
                                            <tr key={i}>
                                                <td>{item.number}</td>
                                                <td>{item.employee_number}</td>
                                                <td>{item.branch_code}</td>
                                                <td>{item.reward_name}</td>
                                            </tr>
                                        )
                                    })
                                }

                                {
                                    !dataLeaderboardUser || !dataLeaderboardTable ? 
                                        <tr>
                                            <td colSpan="4" style={{textAlign: 'center'}}> ไม่พบข้อมูล </td>
                                        </tr>
                                    : null
                                }

                                {
                                    isLoadingTable ? 
                                        <tr className="row-loading">
                                            <td colSpan="4" style={{textAlign: 'center'}}> ...Loading... </td>
                                        </tr>
                                    : null
                                }    
                            </tbody>
                        </table>
                    </div>

                    <div className="py-5">
                        <ButtonNext text="กลับหน้าหลัก" addclass="m-auto" action={()=> navigate('/') } />
                    </div>
                </div>
                ) : null
            }
        </>
    )
}

export default Leaderboard