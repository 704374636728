import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import axios from 'axios'

import ErrorPage from './ErrorPage'
import Loading from '../components/Loading'
import { ButtonNext } from '../components/Button'

/* // For test
const mockupUserAnswer = {"round_id":18,"unique_id":912531,"answer":[1,4]}

const mockupAnswer = {
  "is_correct": 0,
  "correct_answer": [
      1,
      4
  ],
  "reward_name": "-",
  "number": "-"
}

const mockupAnswerCorrect = {
  "is_correct": 1,
  "correct_answer": [
      1,
      4
  ],
  "reward_name": "ทองคำ 1 บาท",
  "number": "2"
}

const mockupQuestion = [
        {
            "question": "บุหรี่ เขียว ราคา 70 บาท อะไรเป็นทางเลือกได้?",
            "choice": [
                {
                    "choice": "สิงโต",
                    "choice_id": 1
                },
                {
                    "choice": "เสือ",
                    "choice_id": 2
                }
            ]
        },
        {
            "question": "บุหรี่ แดง ราคา 70 บาท อะไรเป็นทางเลือกได้?",
            "choice": [
                {
                    "choice": "หนอน",
                    "choice_id": 1
                },
                {
                    "choice": "แมลงวัน",
                    "choice_id": 2
                },
                {
                    "choice": "นกอินทรีย์",
                    "choice_id": 3
                },
                {
                    "choice": "จิงโจ้",
                    "choice_id": 4
                }
            ]
        }
    ]
*/

// Get amount correct answer
let amountCorrectAnswer = 0

function Result() {
  const { state } = useLocation();
  const navigate = useNavigate()
  const { answer, question } = state || ''

  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  // const [isErrorText, setIsErrorText] = useState('')
  const [isErrorNoActiveRound, setIsErrorNoActiveRound] = useState(false)

  const [dataResultAnswer, setDataResultAnswer] = useState({})
  const [dataCorrectAnswer, setDataCorrectAnswer] = useState([])
  const [dataQuestion, setDataQuestion] = useState([])

  const [userCorrect, setUserCorrect] = useState(false)
  const [userCorrectNoReward, setUserCorrectNoReward] = useState(false)
  const [userIncorrect, setUserIncorrect] = useState(false)
  

  const authToken = localStorage.getItem('eeToken')
  const dataUser = JSON.parse(localStorage.getItem('dataUser'))

  const fetchResultAnswer = async (dataStateAnswer) => {
    let dataAnswer = dataStateAnswer

    // For Production
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/ee/answer_question`, 
        dataAnswer, { headers: { 'Authorization': 'Bear '+ authToken }} )

      let resData = response.data
      // Set result answer
      setDataResultAnswer(resData)

      // Set correct answer
      setDataCorrectAnswer(resData.correct_answer)

      // Set amount correct answer
      for(let i=0; i<resData.correct_answer.length; i++) {
        // Check correct answer
        if(resData.correct_answer[i] === dataAnswer.answer[i]) {
          // Increase correct answer
          amountCorrectAnswer = amountCorrectAnswer + 1
        }
      }

      // Set notice award
      if(resData.is_correct) {
        // Correct and no reward
        if(resData.reward_name === '-' || !resData.reward_name || resData.number === '-' || !resData.number) {
          setUserCorrect(false)
          setUserCorrectNoReward(true)
          setUserIncorrect(false)
        } else {
          // Correct and has reward
          setUserCorrect(true)
          setUserCorrectNoReward(false)
          setUserIncorrect(false)
        }
      } else {
        // Incorrect
        setUserCorrect(false)
        setUserCorrectNoReward(false)
        setUserIncorrect(true)
      }

      console.log('fetchResultAnswer',response.data)
      setIsLoading(false)
    } catch (error) {
      console.log('[Error: ',error.response.data,']fetchAnswer', error)

      const errorResText = error.response.data
      if(errorResText === 'no active round' || errorResText === 'incorrect round') {
        setIsErrorNoActiveRound(true)
      } else {
        setIsError(true)
      }
      setIsLoading(false)
    }


    // // For Test
    // // Set result answer
    // setDataResultAnswer(mockupAnswerCorrect)

    // // Set correct answer
    // setDataCorrectAnswer(mockupAnswerCorrect.correct_answer)

    // // Set amount correct answer
    // for(let i=0; i<mockupAnswerCorrect.correct_answer.length; i++) {
    //   console.log(mockupAnswerCorrect.correct_answer[i],' === ',dataAnswer.answer[i])
    //   // Check correct answer
    //   if(mockupAnswerCorrect.correct_answer[i] === dataAnswer.answer[i]) {
    //     // Increase correct answer
    //     amountCorrectAnswer = amountCorrectAnswer + 1
    //   }
    // }

    // // Set notice award
    // if(mockupAnswerCorrect.is_correct) {
    //   // Correct and no reward
    //   if(mockupAnswerCorrect.reward_name === '-' || !mockupAnswerCorrect.reward_name || mockupAnswerCorrect.number === '-' || !mockupAnswerCorrect.number) {
    //     setUserCorrect(false)
    //     setUserCorrectNoReward(true)
    //     setUserIncorrect(false)
    //   } else {
    //     // Correct and has reward
    //     setUserCorrect(true)
    //     setUserCorrectNoReward(false)
    //     setUserIncorrect(false)
    //   }
    // } else {
    //   // Incorrect
    //   setUserCorrect(false)
    //   setUserCorrectNoReward(false)
    //   setUserIncorrect(true)
    // }

  }

  useEffect(() => {
    // Check registered
    const hasDataUser = !!localStorage.getItem("dataUser") 
    if(!hasDataUser) {
        navigate('/')
    }

    // For Production
    // console.log('answer:', answer ? JSON.parse(answer) : null )
    // console.log('question:', question ? JSON.parse(question) : null )
    fetchResultAnswer(answer ? JSON.parse(answer) : null )
    setDataQuestion(question ? JSON.parse(question) : null )

    // For Test
    // console.log(mockupUserAnswer )
    // console.log(mockupQuestion)
    // fetchResultAnswer(mockupUserAnswer)
    // setDataQuestion(mockupQuestion)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Get correct answer text
  function getCorrectAnswerText(ind) {
    let questionNo = dataQuestion[ind]
    let correctAnswerNo = dataCorrectAnswer[ind]
    
    let correctAnswerText = ''

    for(let i=0; i<questionNo.choice.length; i++) {
      if(questionNo.choice[i].choice_id === correctAnswerNo) {
        correctAnswerText = questionNo.choice[i].choice
      }
    }

    return (
      <p className="text-greentextanswer"><span className="font-medium">คำตอบ: </span>{ correctAnswerText }</p> 
    )
  }
  

  return (
    <>
      {/* Check Loading */}
      {
        isLoading ? (
          <div className="absolute w-full h-full">
            <Loading />
          </div>
        ) : null
      }


      {/* Check Error */}
      {
        (isError && !isLoading) ? (
          <ErrorPage text={'ไม่สามารถทำรายการได้'} addclass={'mt-32'} />
        ) : null
      }

      {
        (!isError && !isLoading) ? (
          <div className="pt-7 pb-3 text-center">
            {/* Topic */}
            <p className="font-medium mb-5">รหัสพนักงาน {dataUser.employee_number} สาขา {dataUser.branch_code}</p>

            {/* Notice award */}
            <div className="px-5">
            {
              userCorrect ?
                <div className="p-5 border-dotted border border-redbddotted">
                  <h2 className="text-lg font-semibold leading-tight">ยินดีด้วย คุณตอบถูกเป็นลำดับที่ {dataResultAnswer.number}<br/>รับ{dataResultAnswer.reward_name}</h2>
                  {/* <h2 className="text-lg font-semibold leading-tight">ยินดีด้วยคุณเป็นผู้ตอบถูก<br/>ลำดับที่ {dataResultAnswer.number}<br/>ได้รับ{dataResultAnswer.reward_name}</h2> */}
                </div>
              : null
            }

            {
              userCorrectNoReward ?
                <div className="p-5 border-dotted border border-redbddotted">
                  <h1 className="text-xl font-semibold leading-tight">คุณตอบคำถามถูกต้อง</h1><p className="text-md font-medium">แต่ยังไม่ได้อยู่ในลำดับที่ได้รับรางวัลค่ะ</p> 
                </div>
              : null
            }

            {
              userIncorrect ?
                <div className="p-5 border-dotted border border-redbddotted">
                  <h1 className="text-xl font-semibold leading-tight">คุณตอบคำถามไม่ถูกต้อง</h1><p className="text-md font-medium">กลับมาเล่นใหม่ได้ในรอบถัดไป</p> 
                  {/* <h1 className="text-xl font-semibold leading-tight">คุณตอบคำถามไม่ถูกต้อง</h1><p className="text-base font-medium">ไม่ต้องเสียใจ กลับมาลุ้นใหม่ในรอบถัดไป</p>  */}
                </div>
              : null
            }

            {
              (isErrorNoActiveRound && !isLoading) ?
                <div className="p-5 border-dotted border border-redbddotted">
                  <h1 className="text-xl font-semibold leading-tight">ขอแสดงความเสียใจ<br/>รอบการเล่นจบลงแล้ว</h1><p className="text-md font-medium">ไม่ต้องเสียใจ กลับมาลุ้นใหม่ในรอบถัดไป</p> 
                </div>
              : null
            }
            </div>

            {/* Notice text */}
            <p className="text-sm font-medium p-5">เมื่อกิจกรรมสิ้นสุด สามารถตรวจสอบรายชื่อผู้ได้รับรางวัล ที่หน้าหลัก เมนู “ประกาศรายชื่อผู้ได้รับรางวัล“</p>

            {
              (!isErrorNoActiveRound && !isLoading) ?
                <div className="bg-white p-5 mb-4 text-blacktextquiz">
                  {/* Notice answer */}
                  <p className="font-semibold mb-4">ทบทวนเฉลยคำตอบ คุณตอบถูก { amountCorrectAnswer } ข้อ</p>
                  {
                    (dataQuestion?.length) ? 
                      dataQuestion?.map((item,i)=> {
                        return (
                          <div className="text-left mb-5 last:mb-0" key={i}>
                            <p>{i+1}.{item.question}</p>
                            {
                              getCorrectAnswerText(i)
                            }
                          </div>
                        )
                      })
                    : null
                  }
                </div>
              : null 
            }
          

            <div className="py-5">
              <ButtonNext text="ประกาศรายชื่อผู้ได้รับรางวัล" addclass={'m-auto'} action={()=> navigate('/leaderboard')} />
            </div>
            
            <button className="underline" onClick={()=> navigate('/')}>กลับหน้าหลัก</button>
          </div>
        ) : null
      }
    </>
  )
}

export default Result