import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ButtonNext, ButtonQuiz } from '../components/Button'
import { FaTimes, FaExclamationCircle } from "react-icons/fa";

import axios from 'axios'
import Modal from 'react-modal';

import ErrorPage from './ErrorPage'
import Header from '../components/Header'
import Loading from '../components/Loading'

// const mockup = {
//   "round_id": 18,
//   "unique_id": 913796,
//   "question": [
//       {
//           "question": "บุหรี่ เขียว ราคา 70 บาท อะไรเป็นทางเลือกได้?",
//           "choice": [
//               {
//                   "choice": "สิงโต",
//                   "choice_id": 1
//               },
//               {
//                   "choice": "เสือ",
//                   "choice_id": 2
//               }
//           ]
//       },
//       {
//           "question": "บุหรี่ แดง ราคา 70 บาท อะไรเป็นทางเลือกได้?",
//           "choice": [
//               {
//                   "choice": "หนอน",
//                   "choice_id": 1
//               },
//               {
//                   "choice": "แมลงวัน",
//                   "choice_id": 2
//               },
//               {
//                   "choice": "นกอินทรีย์",
//                   "choice_id": 3
//               },
//               {
//                   "choice": "จิงโจ้",
//                   "choice_id": 4
//               }
//           ]
//       }
//   ]
// }

function Quiz({dataCampaign}) {
  const { state } = useLocation();
  const navigate = useNavigate()
  const btnChoiceRef = useRef([])

  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [errorText, setErrorText] = useState('เกิดข้อผิดพลาก กรุณาลองใหม่อีกครั้ง')

  const [dataQuiz, setDataQuiz] = useState({})
  const [dataRound, setDataRound] = useState({})
  const [dataRandomChoice, setDataRandomChoice] = useState([])
  const [quizNo, setQuizNo] = useState(0)

  const [choiceIdSelectd, setChoiceIdSelectd] = useState(0)
  const [btnChoiceMaxHeight, setBtnChoiceMaxHeight] = useState(0)

  const [dataAnswer, setDataAnswer] = useState({
    "round_id": 0,
    "unique_id": 0,
    "answer":[]
  })

  const authToken = localStorage.getItem('eeToken')

  const fetchQuiz = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/ee/question`, { headers: { 'Authorization': 'Bear '+ authToken }})
      setDataQuiz(response.data)
      
      setDataAnswer({...dataAnswer,
        "round_id": response.data.round_id,
        "unique_id": response.data.unique_id,
      })

      // Random choice
      function shuffleArrayByTimestamp(array) {
        const timestamp = new Date().getTime();
        return array.sort(() => 0.5 - Math.random(timestamp));
      }

      let questionArr = response.data.question
      let choiceArr = []

      // Push random choice to array
      for(let i=0; i<questionArr.length; i++) {
        let shuffledArray = shuffleArrayByTimestamp(response.data.question[i].choice);
        choiceArr.push(shuffledArray)
      }
      setDataRandomChoice(choiceArr)

      console.log('fetchQuiz',response.data)
      setIsLoading(false)
    } catch (error) {
      setIsError(true)
      console.log('[Error]fetcQuiz', error)
      setIsLoading(false)
    }
  }
  
  useEffect(() => {
    // Check registered
    const hasDataUser = !!localStorage.getItem("dataUser") 
    if(!hasDataUser) {
        navigate('/')
    }

    // Set data round
    setDataRound(state)

    // For production
    fetchQuiz()

    // // For test
    // setDataQuiz(mockup)
    // // For test Random choice
    // function shuffleArrayByTimestamp(array) {
    //   const timestamp = new Date().getTime();
    //   return array.sort(() => 0.5 - Math.random(timestamp));
    // }

    // let questionArr = mockup.question
    // let choiceArr = []

    // // Push random choice to array
    // for(let i=0; i<questionArr.length; i++) {
    //   let shuffledArray = shuffleArrayByTimestamp(mockup.question[i].choice);
    //   choiceArr.push(shuffledArray)
    // }
    // setDataRandomChoice(choiceArr)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // Check height chioce button
    if(btnChoiceRef.current.length > 1) {

      let btnChoiceHeightArr = []
      for(let i=0; i<btnChoiceRef.current.length; i++) {
        btnChoiceHeightArr.push(btnChoiceRef.current[i].offsetHeight)
      }

      let maxHeightVal = Math.max(...btnChoiceHeightArr)
      // Set max height
      setBtnChoiceMaxHeight(maxHeightVal)
    }
  }, [dataRandomChoice])
  

  function handleChoiceSelect(e) {
    setOpenModal(false)
    
    let idSelected = e.target.id
    setChoiceIdSelectd(idSelected)

    let answerArr = dataAnswer.answer
    // answerArr.push(Number(idSelected))
    answerArr[quizNo] = Number(idSelected)

    setDataAnswer({...dataAnswer,
      "answer": answerArr
    })
  }

  function setAnswer() {
    let amountQuestion = (dataQuiz?.question.length) - 1
    let amountQuizNo = quizNo
    
      // Check empty answer
      if(!choiceIdSelectd) {
        setErrorText("กรุณาเลือกคำตอบ")
        setOpenModal(true)

      } else {

        // Check amount question different quiz page number
        if(amountQuizNo >= amountQuestion) {
          navigate('/result', { state: { answer: JSON.stringify(dataAnswer), question: JSON.stringify(dataQuiz.question)}})
        } else {
          setQuizNo(quizNo+1)
          setChoiceIdSelectd(0)
        }

      }
      
    
  }
  
  Modal.setAppElement('#root');

  return (
    <>
      {/* Check Loading */}
      {
        isLoading && (
          <div className="absolute w-full h-full">
            <Loading />
          </div>
        )
      }

      {/* Check Error */}
      {
        (isError && !isLoading) && (
          <div className="text-center">
            <ErrorPage text={'ไม่สามารถทำรายการได้'} addclass={'mt-32'} />

            <div className="py-5">
              <ButtonNext text="ประกาศรายชื่อผู้ได้รับรางวัล" addclass={'m-auto'} action={()=> navigate('/leaderboard')} />
            </div>
            
            <button className="underline" onClick={()=> navigate('/')}>กลับหน้าหลัก</button>
          </div>
        )
      }

      {
        (!isError && !isLoading) && (
          <div className="px-5 pt-7 pb-3">
            {/* Topic */}
            <Header text="กิจกรรมพิชิตรางวัล" action={()=> navigate("/")} />
            <p className="text-center font-normal mb-5">
              { dataRound?.name }
            </p>

            {/* Banner */}
            {
              !!dataCampaign?.quiz_banner ?
              <img className="w-full rounded-3xl mb-5" src={dataCampaign?.quiz_banner} alt="banner" /> 
              : null
            }

            {/* Quiz */}
            <div>
              {
                (dataQuiz?.question?.length) ? 
                <>
                  {/* Question */}
                  <p className="text-base font-normal mb-5">{quizNo+1}.{ dataQuiz?.question[quizNo]?.question } </p>

                  {/* Choice */}
                  <ul>
                  {
                    dataRandomChoice[quizNo].map((item,i)=> {
                      return (
                        <li key={i} className="pb-4">
                          <ButtonQuiz elStyle={{'minHeight': btnChoiceMaxHeight}} elRef={(el)=> btnChoiceRef.current[i] = el} id={item.choice_id} idselected={choiceIdSelectd} orderNo={i+1} text={item.choice} action={(e)=> handleChoiceSelect(e)} />
                        </li>
                      )
                    })
                  }
                  </ul>

                  {/* Button submit */}
                  <div className="pt-3 pb-5">
                    <ButtonNext text="ส่งคำตอบ" addclass="m-auto" action={()=> setAnswer() } />
                  </div>
                </>
                : 
                <div className="text-center">
                  <FaExclamationCircle color='#ffffff' size={'3rem'} className="m-auto mb-5" />
                  <p className="text-white w-full flex items-center justify-center">
                    ขออภัยไม่พบกิจกรรมพิชิตรางวัล<br/>
                    กรุณากลับเข้ามาใหม่ในภายหลัง
                  </p>
                </div>
              }
            </div>

            {/* Modal Error */}
            <Modal 
              isOpen={ openModal }
              className="Modal font-kanit"
              overlayClassName="Overlay"
            >
              <div className="Modal-header bg-graymodalheader flex justify-between items-center px-5 py-2">
                <p>คำเตือน</p>
                <div className="rounded-full relative h-full bg-brownbtnclose p-1">
                  <FaTimes color="#ffffff" size={'.8rem'} onClick={()=> setOpenModal(false) } />
                </div>
              </div>
              <div className="Modal-body text-blacktextquiz px-5 py-3">
                <p className="text-center mb-3">{errorText}</p>
              </div>
            </Modal>
          </div>
        )
      }
    </>
  )
}

export default Quiz